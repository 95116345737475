import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

function Faq() {
  const [dropdownList, setDropdownList] = useState({});

  const dropdownHandler = (e) => {
    setDropdownList((prevState) => {
      return {
        ...prevState,
        [e.target.id]: !prevState[e.target.id],
      };
    });
  };
  return (
    <Layout>
      <div className="min-h-full min-w-full bg-semiblack">
        <Helmet>
          <title>{`Frequently Asked Question | Gue Hadir`}</title>
          <meta
            name="description"
            content={`Gue Hadir Ticket Experience System`}
          />
        </Helmet>
        <div className="container mx-auto bg-semiblack">
          <div className="p-5 lg:p-12 bg-semiblack text-white">
            <div className="text-[1.5rem] font-semibold mb-[16px]">
              Frequently Asked Questions
            </div>
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Cara Beli Tiket
                  </div>
                </div>
                <ChevronDownIcon
                  id="caraBeliTiket"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.caraBeliTiket ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.caraBeliTiket
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                Untuk pembelian Tiket event bisa langsung ke website
                www.guehadir.id lalu cari/pilih event dan kategori tiket yang
                diinginkan. Untuk mempermudah mpencarian event, bisa ketik nama
                event di kolom pencarian
              </div>
            </div>
            {/* Cara Registrasi */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Cara Registrasi
                  </div>
                </div>
                <ChevronDownIcon
                  id="caraRegistrasi"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.caraRegistrasi ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.caraRegistrasi
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                Untuk pembelian Tiket event bisa langsung ke website
                www.guehadir.id lalu cari/pilih event dan kategori tiket yang
                diinginkan. Untuk mempermudah mpencarian event, bisa ketik nama
                event di kolom pencarian
              </div>
            </div>
            {/* Tingkatan Member */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Tingkatan Member
                  </div>
                </div>
                <ChevronDownIcon
                  id="tingkatanMember"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.tingkatanMember
                      ? "rotate-[180deg]"
                      : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.tingkatanMember
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                <div>
                  Untuk pembelian Tiket event bisa langsung ke website
                  www.guehadir.id lalu cari/pilih event dan kategori tiket yang
                  diinginkan. Untuk mempermudah mpencarian event, bisa ketik
                  nama event di kolom pencarian
                </div>
                <li>
                  Bronze member - Setelah melakukan minimal pembelian tiket di 5
                  event berbeda setelah awal registrasi (Bronze member akan
                  mendapat potongan harga sebesar 2% setiap melakukan pembelian
                  tiket. Berlaku kelipatan)
                </li>
                <li>
                  Silver Member - Setelah melakukan minimal pembelian tiket di 7
                  event berbeda setelah naik menjadi Bronze member (Silver
                  member akan mendapat potongan harga sebesar 4% setiap
                  melakukan pembelian tiket. Berlaku kelipatan)
                </li>
                <li>
                  Gold Member - Setelah melakukan minimal pembelian tiket di 10
                  event berbeda setelah naik menjadi Silver member (Silver
                  member akan mendapat potongan harga sebesar 6% setiap
                  melakukan pembelian tiket. Berlaku kelipatan)
                </li>
                <li>
                  Diamond Member - Setelah melakukan minimal pembelian tiket di
                  15 event berbeda setelah naik menjadi Gold member (Diamond
                  member akan mendapat potongan harga sebesar 8% setiap
                  melakukan pembelian tiket. Berlaku kelipatan)
                </li>
              </div>
            </div>

            {/* Harga Tiket Event */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Harga Tiket Event
                  </div>
                </div>
                <ChevronDownIcon
                  id="hargaTiketEvent"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.hargaTiketEvent
                      ? "rotate-[180deg]"
                      : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.hargaTiketEvent
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                Harga tiket yang diinfokan pada halaman depan website merupakan
                harga awal yang belum termasuk biaya admin dan pajak. Untuk
                mengetahui total harga, akan diinfokan saat Kamu akan melakuka
                checkout pembelian
              </div>
            </div>
            {/* Cara Bayar */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">Cara Bayar</div>
                </div>
                <ChevronDownIcon
                  id="caraBayar"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.caraBayar ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.caraBayar
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                Cara pembayaran yang bisa digunakan untuk membeli tiket adalah
                melalui Bank Transfer (BCA/Mandiri/BNI/BRI), Bank Virtual
                Account (Bank VA), Qris & Kartu Kredit
              </div>
            </div>
            {/* E-Ticket */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">E-Ticket</div>
                </div>
                <ChevronDownIcon
                  id="eticket"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.eticket ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.eticket
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                Setelah melakukan pembayaran tiket secara yang dipesan, e-ticket
                akan dikirimkan melalui email. Atau kamu bisa cek e-ticket di
                profile kamu pada halaman MY ORDER
              </div>
            </div>
            {/* Penukaran Ticket */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Penukaran Ticket
                  </div>
                </div>
                <ChevronDownIcon
                  id="penukaranTiket"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.penukaranTiket ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.penukaranTiket
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                Untuk E-ticket dapat langsung dibawa ke lokasi acara dan harus
                ditukarkan terlebih dahulu di loket penukaran untuk mendapatkan
                tanda masuk (gelang, rfid / id card)
              </div>
            </div>
            {/* Dokumen Pendukung */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Dokumen Pendukung Penukaran Tiket
                  </div>
                </div>
                <ChevronDownIcon
                  id="dokumenPendukung"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.dokumenPendukung
                      ? "rotate-[180deg]"
                      : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.dokumenPendukung
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                Disarankan untuk membawa e-ticket (bisa dari HP atau print), ID
                (KTP/SIM/Kartu Pelajar) yang sesuai dengan Nama yang tertera di
                E-ticket.
              </div>
            </div>
            {/* Sudah bayar */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Sudah Bayar namun tiket belum diterima
                  </div>
                </div>
                <ChevronDownIcon
                  id="sudahBayar"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.sudahBayar ? "rotate-[180deg]" : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.sudahBayar
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                <div>
                  Silahkan menginformasikan data-data sebagai berikut melalui
                  email cs@guehadir.id atau sosial media guehadir:
                </div>
                <li>Nama</li>
                <li>Booking ID</li>
                <li>No HP</li>
                <li>Alat Bayar yang digunakan</li>
                <li>
                  Apabila bank transfer, maka transfer ke rekening bank apa
                  (BCA,Mandiri,BNI,BRI)
                </li>
                <li>Lampirkan Bukti Transfer</li>
              </div>
            </div>
            {/* Informasi Refund */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Informasi Refund
                  </div>
                </div>
                <ChevronDownIcon
                  id="informasiRefund"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.informasiRefund
                      ? "rotate-[180deg]"
                      : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.informasiRefund
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                <div>
                  Untuk melakukan pengajuan atau pengecekan status refund, kamu
                  bisa masukkan data yang diminta pada halaman REFUND. Proses
                  pengembalian dana kepada pembeli adalah maksimal 40 hari
                  apabila dokumen sudah lengkap dan sesuai.
                </div>
                <div>
                  Refund hanya bisa dilakukan untuk event yang di reschedule
                  atau dibatalkan.
                </div>
              </div>
            </div>
            {/* Informasi Lebih Lanjut */}
            <div className="mt-5 pb-5 border-b border-[#434343]">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-[25px] w-[4px] rounded-md bg-primary"></div>
                  <div className="font-semibold text-[1.2rem]">
                    Informasi Lebih Lanjut
                  </div>
                </div>
                <ChevronDownIcon
                  id="informasiLebihLanjut"
                  onClick={(e) => dropdownHandler(e)}
                  className={`${
                    dropdownList.informasiLebihLanjut
                      ? "rotate-[180deg]"
                      : "rotate-0"
                  } transition-all h-[1.5rem] w-auto cursor-pointer`}
                />
              </div>

              <div
                className={`${
                  dropdownList.informasiLebihLanjut
                    ? "max-h-[100000rem]"
                    : "max-h-0 overflow-hidden"
                } transition-all text-[#DCDCDC] mt-2`}
              >
                <div>
                  Apabila kamu belum menemukan jawaban dari maslah yang kamu
                  hadapi saat melakukan pembelian tiket di www.guehadir.id ,
                  silahkan hubungi kami melalui email cs@guehadir.id
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Faq;
