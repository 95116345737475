import ListImages from "../assets";

const getPaymentMethodImage = (bankCode) => {
    switch (bankCode) {
        case '014':
            return ListImages.bca
        case '002':
            return ListImages.bri
        case '022':
            return ListImages.cimb//CIMB
        case '009':
            return ListImages.bni
        case '008':
            return ListImages.mandiri
        case '013':
            return ListImages.permata
        case '011':
            return ListImages.danamon
        case 'CREDITCARD':
            return ListImages.credit
        case 'GOPAY':
            return ListImages.gopay
        case 'bca_va':
            return ListImages.bca
        case 'QR':
            return ListImages.qris
        default:
            return null
    }
}

export default getPaymentMethodImage
